import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/Reset/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import ResetTable from 'components/Web_User_Interface/1440p_Series/System/Reset/resetTable';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/System/Reset/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // Camera Reset",
  "path": "/Web_User_Interface/1440p_Series/System/Reset/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Use this function to reset your camera. It is not recommended to simply unplug/replug your camera or use the hardware reset unnecessarily (those actions can be harmful to running operations as well as causing hardware defects on the internal SD card). Please always use the soft- restart and reset via the web user interface. Use the hardware reset in case that you have lost access to the interface.",
  "image": "./WebUI_1440p_SearchThumb_System_Reset.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_Reset.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // Camera Reset' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use this function to reset your camera. It is not recommended to simply unplug/replug your camera or use the hardware reset unnecessarily (those actions can be harmful to running operations as well as causing hardware defects on the internal SD card). Please always use the soft- restart and reset via the web user interface. Use the hardware reset in case that you have lost access to the interface.' image='/images/Search/WebUI_1440p_SearchThumb_System_Reset.png' twitter='/images/Search/WebUI_1440p_SearchThumb_System_Reset.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/System/Werksreset/' locationFR='/fr/Web_User_Interface/1440p_Series/System/Reset/' crumbLabel="Camera Reset" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b633982339a294e6303363d0ca22b8b1/e4a12/1440p_Settings_System_Reset.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB9UlEQVQoz3WSS2sTURiG8zMsglBKO8nciiQRbTJJM5O0ySRzOWeuTVJsCi24LNiitkjXLt1pXXihuhR/4SMzMRSxLh7eb/XwHt5TMU0TXTeobW3QcCK8q1+Mzn+w//I7ztntvdhnt7jntyTXPxmnxzx6+IC1tTXW19epaJqGpukomxs8tX2mV1/JLj6QvbohPi/4eMfFDcnFTZnT1594/vYLzjhFV1V0fRvD2KaiqioF1WqNRv0xqfRIxIRUeOW9ZEImPYQ3JJzsE4wHTIZ9/NEAe9fBshx6uwNare6qoUa1WqXZfEKWH5BmeUlxJ2mKjCJkFCNlhJARw5GL647xg5Dh3ohet0e3Y7PzzLoTKopCo9EgyzLyPC9J05QkSZFSIoQosyAMQ+bzGYvFMSLos9fv0bGcf4XNZpMkSYiiqKSQrFjJSoQgkpIoipnlhbBLx7Jpt+4RHh4eMp1Omc2mxHFcUjYMA0TgIUIfX0o8KQmkJA37OLsdBnYPq92mUqvV/ttwSYwQISKdI+enyINjjoKQEz/gSEhe5AGD3lLY2mlTKcbQdf3PKEvhX08spKFPdHpJdvmN5Ow979yAzyOPa9fnzSIhcW06nUJoLRsWwlXTYph6vU7x4Q3DWGaBrmKoCoZWpWaaKKZZZsPUMDWFzU2FrS2F30XFdDmWBA5EAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b633982339a294e6303363d0ca22b8b1/e4706/1440p_Settings_System_Reset.avif 230w", "/en/static/b633982339a294e6303363d0ca22b8b1/d1af7/1440p_Settings_System_Reset.avif 460w", "/en/static/b633982339a294e6303363d0ca22b8b1/7f308/1440p_Settings_System_Reset.avif 920w", "/en/static/b633982339a294e6303363d0ca22b8b1/43a10/1440p_Settings_System_Reset.avif 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b633982339a294e6303363d0ca22b8b1/a0b58/1440p_Settings_System_Reset.webp 230w", "/en/static/b633982339a294e6303363d0ca22b8b1/bc10c/1440p_Settings_System_Reset.webp 460w", "/en/static/b633982339a294e6303363d0ca22b8b1/966d8/1440p_Settings_System_Reset.webp 920w", "/en/static/b633982339a294e6303363d0ca22b8b1/095e2/1440p_Settings_System_Reset.webp 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b633982339a294e6303363d0ca22b8b1/81c8e/1440p_Settings_System_Reset.png 230w", "/en/static/b633982339a294e6303363d0ca22b8b1/08a84/1440p_Settings_System_Reset.png 460w", "/en/static/b633982339a294e6303363d0ca22b8b1/c0255/1440p_Settings_System_Reset.png 920w", "/en/static/b633982339a294e6303363d0ca22b8b1/e4a12/1440p_Settings_System_Reset.png 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b633982339a294e6303363d0ca22b8b1/c0255/1440p_Settings_System_Reset.png",
              "alt": "Web User Interface - 1440p Series - System Reset",
              "title": "Web User Interface - 1440p Series - System Reset",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ResetTable mdxType="ResetTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "camera-reset--wps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-reset--wps",
        "aria-label": "camera reset  wps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Reset & WPS`}</h2>
    <p>{`If you are no longer able to access your cameras web user interface you can press the reset button on your camera. You will see the Status LEDs turn off and...`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`After 3s`}</strong>{`: Turn the Status LEDs on and start the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Network/WiFi/"
        }}>{`Wireless-Protected-Setup`}</a>{` or `}<strong parentName="li">{`WPS`}</strong>{` mode that allows you to add your camera to your local WiFi. Press the button again for `}<strong parentName="li">{`2s`}</strong>{` to quit the mode before the 2 minute timeout.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 15s`}</strong>{`: Let the Status LEDs flash slowly and after releasing the button reset all your camera settings `}<strong parentName="li">{`except the network configuration`}</strong>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 20s`}</strong>{`: Let the Status LEDs flash fast and after releasing the button reset `}<strong parentName="li">{`ALL`}</strong>{` your camera settings.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 30s`}</strong>{`: Turn the Status LEDs off and roll back your camera to the state it had before the last Firmware update and restart your camera.`}</li>
    </ol>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9420_WQHD/Camera_Reset/"
        }}>{`IN-9420 2k+ WQHD Factory Reset`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Camera_Reset/"
        }}>{`IN-9408 2k+ WQHD Factory Reset`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8401_WQHD/Camera_Reset/"
        }}>{`IN-8401 2k+ WQHD Factory Reset`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      